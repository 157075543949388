import { container, title } from "@assets/jss/index.js";

const componentsStyle = {
  '@global': {
    body: {
      fontFamily: '"Hiragino Sans GB", "Microsoft Yahei", "微软雅黑", "Roboto", "Helvetica", "Arial", sans-serif',
      margin: "0px",
      color: "#333"
    },
    p: {
      fontSize: "1.25em"
    },
    a: {
      color: "#c94700",
      boxShadow: "none"
    }
  },
  container:{
    textAlign: "left",
    zIndex: "12",
    ...container
  },
  titleBar: {
    background:
      'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
      'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  brand: {
    fontFamily: '"Hiragino Sans GB", "Microsoft Yahei", "微软雅黑", "Roboto", "Helvetica", "Arial", sans-serif',
    color: "#efefef",
    textAlign: "left",
    zIndex: "12"
  },
  title: {
    fontFamily: '"Hiragino Sans GB", "Microsoft Yahei", "微软雅黑", "Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: "3.2rem",
    fontWeight: "600",
    display: "inline-block",
    position: "relative"
  },  
  subtitle2: {
    ...title,
    textAlign: "center",
    txetSize: "1.313em",
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
  subtitle: {
    fontSize: "1.73216rem",
    textAlign: "center",
    maxWidth: "100%",
    margin: "10px 0 0"
  },
  main: {
    minHeight: "70vh",
    backgroundColor: "#efefef",
    position: "relative",
    zIndex: "3"
  },
  mainRaised: {
    margin: "-60px auto 0px",
    padding: "10px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  },
  '@media (min-width: 1024px)': {
    mainRaised: {
      paddingTop: "60px",
      borderRadius: "6px",
      paddingBottom: "60px",
      margin: "-60px 30px 0px"
    }
  },
  link: {
    textDecoration: "none"
  },
  textCenter: {
    textAlign: "center"
  },
  cardTitle: {
    float: "right",
    padding: "10px 10px 10px 0px",
    lineHeight: "24px",
    fontWeight: "900",
    fontSize: "1.2em",
    textRendering: "optimizeLegibility",
    letterSpacing: "0.140625em"
  },
  imgCardTop: {
    width: "100%",
    borderTopLeftRadius: "calc(.25rem - 1px)",
    borderTopRightRadius: "calc(.25rem - 1px)"
  },
  imgCardBottom: {
    width: "100%",
    borderBottomLeftRadius: "calc(.25rem - 1px)",
    borderBottomRightRadius: "calc(.25rem - 1px)"
  },
  imgCard: {
    width: "100%",
    borderRadius: "calc(.25rem - 1px)"
  },
  imgCardOverlay: {
    position: "absolute",
    top: "0",
    right: "0",
    bottom: "0",
    left: "0",
    padding: "1.25rem"
  }
};

export default componentsStyle;
