import React from "react"
import { graphql } from "gatsby"
import Bio from "@components/bio"
import Layout from "@components/layout"
import SEO from "@components/seo"
import GridContainer from '@components/Grid/GridContainer'
import GridItem from '@components/Grid/GridItem'
import clsx from 'clsx'
import styles from '@assets/jss/views/components.js'
import { makeStyles } from '@material-ui/core/styles'
import Parallax from '@components/Parallax/Parallax'
import postsStyle from '@assets/jss/posts.js'
import Teams from '@components/teams'
const useStyles = makeStyles({
  ...styles,
  ...postsStyle,
})

const Teachers  = (props) => {
  const { data } = props
  const siteTitle = data.site.siteMetadata.title
  const teachers = data.allMdx.edges
  const classes = useStyles()
  // console.log(teachers)
  return (
    <Layout location={props.location} title={siteTitle}>
      <SEO title="教师 teachers" />
      <Parallax
        filter
        image={require("@assets/img/landing-bg.jpg")}
        tiny
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                <Bio 
                  title="师资介绍"
                  style={{ position: "static" }}
                /> 
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={clsx(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <Teams teachers={teachers} />
        </div>
      </div>
    </Layout>
  )
}


export default Teachers

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(filter: {frontmatter: {type: {eq: "teachers"}, visible: {eq: true}}}, sort: { fields: [frontmatter___date], order: ASC }) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            position
            title
            description
            type
            avatar {
              childImageSharp {
                fixed(width: 200, height: 200, fit: COVER) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            featuredImage {
              childImageSharp {
                fixed(width: 300, height: 200, fit: COVER) {
                  ...GatsbyImageSharpFixed
                }
                fluid(maxWidth: 960) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
