const postsStyle = {
  root: {
    maxWidth: "100%",
    height: "auto",
    // marginTop: 16,
    marginBottom: 24,
    // backgroundImage: `linear-gradient(to top, rgb(250, 246, 250) 0%, rgb(255, 248, 255) 100%)`,
    color: "#333",
    display: "flex",
    "@media (min-width: 960px)": {
      minHeight: "200px",
    },
  },
  link: {
    display: 'flex',
    minWidth: '100%',
    color: '#541600',
    // color: '#fffeeb',
    "@media (min-width: 960px)": {
      color: '#541600',        
    }
  },
  // description on image
  // wrap: {
  //   minWidth: '100%',
  //   height: 'auto',
  //   display: 'flex',
  //   position: 'relative',
  //   alignItems: 'flex-end',
  //   flexDirection: 'row',
  //   "@media (min-width: 960px)": {
  //     alignItems: 'flex-start',      
  //     justifyContent: 'flex-start',
  //     flexDirection: 'row'
  //   }
  // },
  // media: {
  //   flexShrink: 0,
  // },
  // content: {
  //   left: 0,
  //   right: 0,
  //   height: '200px',
  //   display: 'flex',
  //   flexDirection: 'column',
  //   position: 'absolute',
  //   background: 'rgba(0, 0, 0, 0.5)',
  //   alignItems: 'flex-start',
  //   "@media (min-width: 960px)": {
  //     position: 'static',
  //     width:'100%',
  //     background: '#f6f6f6',
  //   }
  // },
  noImage: {
    left: 0,
    right: 0,
    color: '#541600',
    minHeight: '200px',
    display: 'flex',
    flexDirection: 'column',
    position: 'static',
    width:'100%',
    background: '#f6f6f6',
  },
  wrap: {
    // position: "relative",    
    // overflow: "hidden",
    display: "flex",
    alignSelf: "flex-start",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    "@media (min-width: 960px)": {
      flexDirection: "row",
    },
  },
  imageWrap: {
    position: "relative",
    // overflow: "hidden",
    width: "100%",
    height: "100%",
    zIndex: "0",
    "&:after": {
      backgroundColor: "#fcfcfc",
      width: "150%",
      transform: "rotate(-3deg) skewX(0deg)",
      opacity: "1",
      content: '""',
      position: "absolute",
      bottom: "-19%",
      left: "-25%",
      height: "30%",
      borderRadius: "500px 200px 239px 200px / 123px 0px 121px 0px",
      transition: "all .4s",
      zIndex: "2",
    },
    "&:before": {
      backgroundColor: "#E83114",
      //background-color:transparent",
      width: "150%",
      transform: "rotate(-4deg) skewX(0deg)",
      opacity: "0.6",
      content: '""',
      position: "absolute",
      bottom: "-6%",
      height: "30%",
      left: "-12%",
      borderRadius: "720px 0px 239px 0px / 123px 0px 121px 0px",
      transition: "all .4s",
      zIndex: "1",
    },
  },
  imageWrapDark: {
    paddingTop: "5%",
    paddingBottom: "10%",
    "&:after": {
      backgroundColor: "#541600",
      opacity: "0.6",
      bottom: "-19%",
    },
    "&:before": {
      transform: "rotate(-6deg) skewX(0deg)",
      bottom: "-9%"
    }
  },
  bottomText: {
    position: "absolute",
    textAlign: "right",
    right: "10px",
    bottom: "2%",
    zIndex: "25",
  },
  content: {
    display: "flex",
    flex: "auto",
    flexDirection: "column",
    alignSelf: "flex-start",
    position: "relative",
    width: "100%",
    height: "100%",
    backgroundColor: "#fcfcfc",
    zIndex: "0",
    "@media (min-width: 960px)": {
      backgroundColor: "transparent",
    },
  },
  media: {
    width: "100%",
    height: "100%",
    "@media (min-width: 1000px)": {
      flexShrink: 0,
    },
  },
  // image up and description down
  // wrap: {
  //   display: "flex",
  //   alignSelf: "flex-start",
  //   flexDirection: "column",
  //   height: "100%",
  //   "@media (min-width: 900px)": {
  //     flexDirection: "row",
  //   },
  // },
  // content: {
  //   display: "flex",
  //   flex: "auto",
  //   flexDirection: "column",
  //   alignSelf: "flex-start"
  // },
  // media: {
  //   "@media (min-width: 1000px)": {
  //     flexShrink: 0,
  //   },
  // },
}

export default postsStyle
