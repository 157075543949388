import React from "react";
// nodejs library that concatenates classes
import clsx from "clsx";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { graphql, useStaticQuery } from "gatsby"
// import { MDXRenderer } from 'gatsby-plugin-mdx'
// @material-ui/icons
import Link from '@components/Link'
import Image from 'gatsby-image'
// core components
import GridContainer from "@components/Grid/GridContainer.js";
import GridItem from "@components/Grid/GridItem.js";
// import Button from "@components/CustomButtons/Button.js";
import Card from "@components/Card/Card.js";
import CardBody from "@components/Card/CardBody.js";
// import CardFooter from "@components/Card/CardFooter.js";

import styles from "@assets/jss/views/landingPageSections/teamStyle.js";

const useStyles = makeStyles(styles);

export default function Teams({ teachers }) {
  const classes = useStyles();
  const imageData = useStaticQuery(graphql`
    query profileImageQuery {
      avatar: file(absolutePath: { regex: "/avatar.png/" }) {
        childImageSharp {
          fixed(width: 200, height: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  const imageClasses = clsx(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  return (
    <div className={classes.section}>
      <div>
        <GridContainer justify="center" alignItems="center">
          {teachers.map( ({ node }) => {
            return (
              <GridItem xs={12} sm={12} md={4} key={node.id}>
                <Card plain>
                <Link to={"/" + node.frontmatter.type + node.fields.slug} style={{ textDecoration: "none" }}>
                  <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                    <Image fixed={
                      node.frontmatter.avatar ?
                      node.frontmatter.avatar.childImageSharp.fixed :
                      imageData.avatar.childImageSharp.fixed
                    } alt="avatar" className={imageClasses} />
                  </GridItem>
                  <h4 className={classes.cardTitle}>
                    {node.frontmatter.title}
                    <br />
                    <small className={classes.smallTitle}>{node.frontmatter.position}</small>
                  </h4>
                  <CardBody>
                    <p className={classes.description}>                      
                      {node.frontmatter.description}
                    </p>
                  </CardBody>
                  </Link>
                </Card>
              </GridItem>
            )
          })}
        </GridContainer>
      </div>
    </div>
  );
}
