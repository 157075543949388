import React from 'react'
import styles from '@assets/jss/imagesStyles.js'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import Image from 'gatsby-image'
// import styled from "styled-components"

// import { rhythm } from "../utils/typography"
const useStyles = makeStyles({
  ...styles,
  wrap: {
    width: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  bio: {
    marginRight: 'auto',
    marginLeft: 'auto',
    paddingTop: '20px',
    paddingBottom: '30px',
    color: '#ffeedb',
    textAlign: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    position: 'absolute',
    paddingLeft: '50px',
    paddingRight: '50px',
    zIndex: '50',
    borderRadius: '50px 0 50px 0',
  },
  bioSmall: {
    padding: '20px',
  },
  bioImage: {
    marginTop: '-80px',
  },
})

function Bio({ title, avatar, style, small }) {
  const classes = useStyles()
  const imageClasses = clsx(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid,
    classes.bioImage
  )
  return (
    <div className={clsx({[classes.bio]: true, [classes.bioSmall]: small})} style={style}>
      {avatar && (
        <Image
          fixed={avatar.childImageSharp.fixed}
          alt="avatar"
          className={imageClasses}
        />
      )}
      <Typography
        variant={small ? "h6" : "h4"}
        component="h3"
        style={{
          marginTop: 8,
        }}
      >
        {title}
      </Typography>
    </div>
  )
}

export default Bio
